
<template>
  <div class="speaking-page-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @click.native="startPlayingVideo('socket')"
      v-if="isfinishCheck"
    ></PageButton>
    <div class="speaking-page-content">
      <!-- 失败和成功背景 -->
      <div class="video-area">
        <!-- src="https://static.nihaocafe.com/video/df3a3a6a-bc71-43f3-b523-de0cecffc8e3.mp4" -->
        <video
          class="video-player"
          ref="videoBox"
          :autoplay="false"
          @canplay="getDuration"
          @timeupdate="toRecordPage"
          :poster="pageInfo.bgImg"
        ></video>
      </div>
      <div class="title-area" v-if="pageInfo.titlePinyin && !isVideoPlay">
        <div class="title-content">
          <div class="words pinyin font-pinyin-medium">
            {{ pageInfo.titlePinyin }}
          </div>
          <div class="words font-hanzi-medium">
            {{ pageInfo.titleHanzi }}
          </div>
        </div>
      </div>

      <div class="recorder-area" v-if="isRecordPageOpen">
        <div
          class="checkGif"
          :class="{ checkGifWrong: !checkGif }"
          v-checkgif="checkGif"
          v-if="isShowCheckGif"
        ></div>
        <div class="red-background-border"></div>
        <div class="recorder-content">
          <div class="trumpet-area">
            <div class="trumpet-content">
              <img
                src="@/assets/img/06-Sound/sound-white.svg"
                alt=""
                @click="playVoice('socket')"
              />
            </div>
          </div>

          <div class="words-area">
            <div class="words-content">
              <div
                class="word-item"
                v-for="(item, index) in currentWordsInfo.singleWordList"
                :key="index"
                :class="{
                  watingStatus: isFinishRecording && item.status === 2,
                  correctColor: item.status != 0 && isFinishRecording,
                  wrongColor: item.status === 0 && isFinishRecording,
                }"
              >
                <div class="words pinyin font-pinyin-medium">
                  {{ item.pinyin }}
                </div>
                <div class="words font-hanzi-medium">
                  {{ item.hanzi }}
                </div>
              </div>
            </div>
          </div>
          <!-- 录音 -->
          <div class="recorder-button" v-if="!isFinishRecording">
            <div class="button-img" @click="startRecorder('socket')">
              <img
                src="@/assets/img/16-GAMES/G14-tape-recorder/record.svg"
                v-if="!isStartRecording"
              />
              <img
                src="@/assets/img/16-GAMES/G14-tape-recorder/record-start.svg"
                alt=""
                v-else
              />
            </div>
            <div class="recorder-time" v-if="isShowTime">
              {{ recorderTime }}
            </div>
          </div>
          <!-- 评分 -->
          <div class="finish-record-area" v-else>
            <div class="button-play">
              <img
                src="@/assets/img/16-GAMES/G14-tape-recorder/button-play.svg"
                alt=""
                @click="playRecorder('socket')"
              />
            </div>
            <div class="button-record">
              <img
                src="@/assets/img/16-GAMES/G14-tape-recorder/record-next.svg"
                alt=""
                @click="backToRecord('socket')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Recorder from "js-audio-recorder";
import { audioToMp3 } from "@/assets/js/axios/kidsApi.js";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  components: {
    PageButton,
  },
  props: {
    pageInfo: {
      type: Object,
      require: true,
    },
    wordsInfoList: {
      type: Array,
      require: true,
    },
  },
  created() {
    this.recorder = new Recorder({
      ...this.recorderOption,
    });
    this.currentWordsInfo = this.wordsInfoList[this.currentStep];
    // console.log(this.$route);
  },
  data() {
    return {
      checkGif: true,
      isShowCheckGif: false,
      recorderOption: {
        sampleBits: 8, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
        // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
      },
      recorder: null,

      isVideoPlay: false,

      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "play",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },
      videoDom: "",
      // isVideoPlay: false,
      duration: "",
      currentTime: "",
      isRecordPageOpen: false,
      currentWordsInfo: {},
      isStartRecording: false,
      isShowTime: false,
      timeNum: 0,
      recorderTime: "",
      currentStep: 0,
      isFinishRecording: false,
      isfinishCheck: true,
      recorderUrl: "",

      playVideoSocketInfo: {},
      playVoiceSocketInfo: {},
      recorderSocketInfo: {},
      nextRecorderSocketInfo: {},
      palyRecorderSocketInfo: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.videoDom = this.$refs.videoBox;
    });
    this.$bus.$on("getTeacherRate", ({ wrongList, correctList }) => {
      this.checkWords(wrongList, correctList);
    });
    this.$bus.$on("recordButton", () => {
      this.startRecorder("socket", true);
    });
    this.$bus.$on("nextRecordButton", () => {
      this.backToRecord("socket", true);
    });
    this.$bus.$on("playVideoButton", () => {
      this.startPlayingVideo("socket", true);
    });
    this.$bus.$on("clickVoiceButton", () => {
      this.playVoice("socket", true);
    });
    this.$bus.$on("palyRecorder", () => {
      this.playRecorder("socket", true);
    });
  },
  watch: {
    isShowTime(value) {
      if (!value) {
        // 发送socket信息
        // const
        this.getMp3Data().then((res) => {
          // console.log(res);
          this.recorderUrl = res;
          const data = {
            clickType: 11000,
            data: { value: res },
            text: "speakingPage录音socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
          // 提醒老师接受音频
          console.log(this.sendSocketInfo);
          this.isFinishRecording = true;
        });
      }
    },
    playVideoSocketInfo: {
      handler() {
        const data = {
          clickType: 2003301,
          data: { value: null },
          text: "speakingPage组件播放按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        console.log(this.sendSocketInfo);
      },
      deep: true,
    },
    playVoiceSocketInfo: {
      handler() {
        const data = {
          clickType: 2003302,
          data: { value: null },
          text: "speakingPage组件播放按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        console.log(this.sendSocketInfo);
      },
      deep: true,
    },
    recorderSocketInfo: {
      handler() {
        const data = {
          clickType: 2003303,
          data: { value: null },
          text: "speakingPage组件录音按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        console.log(this.sendSocketInfo);
      },
      deep: true,
    },
    nextRecorderSocketInfo: {
      handler() {
        const data = {
          clickType: 2003304,
          data: { value: null },
          text: "speakingPage组件再次录音按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        console.log(this.sendSocketInfo);
      },
      deep: true,
    },
    palyRecorderSocketInfo: {
      handler() {
        const data = {
          clickType: 2003305,
          data: { value: null },
          text: "speakingPage组件再次录音按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        console.log(this.sendSocketInfo);
      },
      deep: true,
    },
  },
  beforeDestroy() {},
  methods: {
    playVoice(socket, isFromSocket = false) {
      // to do list : 播放语音
      console.log("播放语音");
      if (!isFromSocket) {
        this.playVoiceSocketInfo = {
          value: Math.random(),
        };
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    startPlayingVideo(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.playVideoSocketInfo = {
          value: Math.random(),
        };
      }
      this.videoDom.play();
      this.isVideoPlay = true;
      this.isfinishCheck = false;
      this.isRecordPageOpen = false;
      this.isFinishRecording = false;
      this.currentWordsInfo = this.wordsInfoList[this.currentStep];
    },
    getDuration() {
      this.duration = this.videoDom.duration;
      console.log(this.duration);
    },
    toRecordPage() {
      this.currentTime = this.videoDom.currentTime;
      // console.log(this.currentTime);
      if (this.currentTime >= this.currentWordsInfo.timeStamp) {
        this.videoDom.pause();
        this.isRecordPageOpen = true;
      }
    },

    /**
     *  录音的具体操作功能
     * */
    // 开始录音
    startRecorder(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.recorderSocketInfo = {
          value: Math.random(),
        };
      }
      this.isStartRecording = !this.isStartRecording;
      if (this.isStartRecording) {
        this.recorder.start();
        this.isShowTime = true;
        this.timer = setInterval(() => {
          this.timeNum++;
          this.recorderTime = "00:" + String(this.timeNum).padStart(2, "0");
        }, 1000);
      } else {
        this.timeNum = 0;
        this.recorderTime = "";
        clearInterval(this.timer);
        this.isShowTime = false;
        this.recorder.stop();
      }
    },
    // 录音播放
    playRecorder(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.palyRecorderSocketInfo = {
          value: Math.random(),
        };
      }
      this.recorder.play();
    },
    destroyRecorder() {
      this.recorder.destroy();
    },
    backToRecord(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.nextRecorderSocketInfo = {
          value: Math.random(),
        };
      }
      this.isFinishRecording = false;
      this.destroyRecorder();
    },
    // 判断正确
    checkWords(wrongList, correctList) {
      wrongList.forEach((item) => {
        this.$emit("changeWordStatus", this.currentStep, item, 0);
      });
      correctList.forEach((item) => {
        this.$emit("changeWordStatus", this.currentStep, item, 1);
      });
      if (wrongList.length === 0) {
        // 正确 继续播放视频等操作
        this.isShowCheckGif = true;
        this.checkGif = true;
        playCorrectSound();
        startConfetti();
        setTimeout(() => {
          this.isShowCheckGif = false;
          this.currentStep++;
          if (this.currentStep >= this.wordsInfoList.length - 1) {
            this.currentStep = this.wordsInfoList.length - 1;
            this.isLastStep = true;
          }
          this.isfinishCheck = true;
          this.isFinishRecording = true;
        }, 500);
      } else {
        // 错误  字体变色

        this.isShowCheckGif = true;
        this.checkGif = false;
        playCorrectSound(false);

        setTimeout(() => {
          this.isShowCheckGif = false;
        }, 1000);
      }
    },
    async getMp3Data() {
      // const formData = new FormData();
      const blob = this.recorder.getWAVBlob(); // 获取wav格式音频数据
      const calendarEventId = this.$route.params.calendarEventId;
      const formData = new FormData();

      formData.append("uploadFile", blob);
      formData.append("calendarEventId", calendarEventId);
      formData.append("oldAudio", this.recorderUrl);

      const res = await audioToMp3(formData);
      return res.data;
    },
  },
};
</script>
<style scoped lang="scss">
.speaking-page-container {
  .speaking-page-content {
    width: 100%;
    height: 100%;
    position: relative;
    .video-area {
      width: 100%;
      height: 100%;
      .video-player {
        border-radius: 58px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title-area {
      position: absolute;
      width: 30%;
      height: 18%;
      left: 0;
      top: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #cd4c3f;
      color: #fff;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
    .recorder-area {
      width: 70%;
      height: 65%;
      position: absolute;
      top: 10%;
      left: 15%;
      .recorder-content {
        position: absolute;
        background: #224e96;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border-radius: 56px;
        z-index: 3;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        .trumpet-area,
        .words-area,
        .recorder-button,
        .finish-record-area {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .trumpet-area {
          .trumpet-content {
            width: 60px;
            height: 60px;
            background: #cd4c3f;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 60%;
              cursor: pointer;
            }
          }
        }
        .words-area {
          width: 100%;
          .words-content {
            height: 50%;
            color: #224e96;
            padding: 5% 10%;
            background: #fff;
            border-radius: 20px;
            display: flex;
          }
        }
        .recorder-button {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .button-img {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 90%;
              cursor: pointer;
            }
          }
          .recorder-time {
            color: #fff;
            position: absolute;
            left: -50%;
          }
        }
        .finish-record-area {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .button-play,
          .button-record {
            display: flex;
            justify-content: flex-end;
            img {
              width: 70%;
              cursor: pointer;
            }
          }
        }
      }
    }
    .teacher-rate-area {
      background-image: url("../../../../assets/img/16-GAMES/G14-tape-recorder/controls.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      width: 70%;
      height: 20%;
      left: 15%;
      img {
        height: 100%;
      }
    }

    .words {
      text-align: center;
    }
    .watingStatus {
      opacity: 0.3;
    }
    .correctColor {
      color: #35af76;
      opacity: 1;
    }
    .wrongColor {
      color: #cd4c3f;
    }
  }
}
</style>
